import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from 'styled-components';

const FormStyles = styled(Form)`
    background: var(--white);
    margin: 15px 0;
    text-align: left;
}
`;

const Fieldset = styled.fieldset`
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
`;

const Input = styled(Field)`
  width: 100%;
  border: 0;
  border-bottom: ${(props) => props.border || '1px solid #ccc'};
  touch-action: manipulation;

  padding: 1.8rem 1rem 0.6rem;
  font-size: 1.6rem;
  transition: border-color 0.2s ease;
  caret-color: black;
  background-color: transparent;
  outline: none;
  &::placeholder {
    color: rgba(0, 0, 0, 0);
  }
  &:focus + .label::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  &:placeholder-shown + .label::before {
    transform: translate3d(0, -3.4rem, 0) scale3d(1, 1, 1);
  }
  &:focus + .label::before {
    transform: translate3d(0, -5.52rem, 0) scale3d(0.82, 0.82, 1);
  }
  &:focus + .label::before {
    color: var(--black);
  }
`;

const Textarea = styled(Field)`
  width: 100%;
  border: 0;
  border-bottom: ${(props) => props.border || '1px solid #ccc'};
  touch-action: manipulation;

  padding: 1.8rem 1rem 0.6rem;
  font-size: 1.6rem;
  transition: border-color 0.2s ease;
  caret-color: black;
  background-color: transparent;
  outline: none;
  &::placeholder {
    color: rgba(0, 0, 0, 0);
  }
  &:focus + .label::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  &:placeholder-shown + .label::before {
    transform: translate3d(0, -9.4rem, 0) scale3d(1, 1, 1);
  }
  &:focus + .label::before {
    transform: translate3d(0, -11.22rem, 0) scale3d(0.82, 0.82, 1);
  }
  &:focus + .label::before {
    color: var(--black);
  }
`;

const Label = styled.label`
  font-size: 1.6rem;
  display: block;
  position: relative;
  max-height: 0;
  font-weight: 500;
  pointer-events: none;
  outline: none;

  &::before {
    color: var(--label__color);
    content: attr(data-content);
    display: inline-block;
    filter: blur(0);
    backface-visibility: hidden;
    transform-origin: left top;
    transition: transform 0.2s ease;
    left: 1rem;
    position: relative;
  }
  /* &::after {
    bottom: 1rem;
    content: "";
    height: 0.1rem;
    position: absolute;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
    opacity: 0;
    left: 0;
    top: 100%;
    margin-top: -0.5rem;
    transform: scale3d(0, 1, 1);
    width: 100%;
    background-color: var(--color__accent);
  } */
  &::before {
    transform: translate3d(0, -5.52rem, 0) scale3d(0.82, 0.82, 1);
  }
  .hidden--visually {
    border: 0;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

const LabelTextarea = styled.label`
  font-size: 1.6rem;
  display: block;
  position: relative;
  max-height: 0;
  font-weight: 500;
  pointer-events: none;
  outline: none;

  &::before {
    color: var(--label__color);
    content: attr(data-content);
    display: inline-block;
    filter: blur(0);
    backface-visibility: hidden;
    transform-origin: left top;
    transition: transform 0.2s ease;
    left: 1rem;
    position: relative;
  }
  /* &::after {
    bottom: 1rem;
    content: "";
    height: 0.1rem;
    position: absolute;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
    opacity: 0;
    left: 0;
    top: 100%;
    margin-top: -0.5rem;
    transform: scale3d(0, 1, 1);
    width: 100%;
    background-color: var(--color__accent);
  } */
  &::before {
    transform: translate3d(0, -11.22rem, 0) scale3d(0.82, 0.82, 1);
  }
  .hidden--visually {
    border: 0;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 2rem;
  background-color: var(--field__background);
  transition: background-color 0.2s ease;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &:hover,
  &:focus-within {
    background-color: var(--field__background--hover);
  }
  &:hover .floating__input {
    border-color: var(--input__border--hover);
  }
`;

const ButtonSubmit = styled.button.attrs({
  className: 'btn btn-lg btn-black ',
})`
  cursor: pointer;
  text-align: left;
`;

const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const ContactForm = ({ form }) => (
  <Formik
    initialValues={{
      name: '',
      email: '',
      message: '',
    }}
    onSubmit={(values, actions) => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': form, ...values }),
      })
        .then(() => {
          alert('Success');
          actions.resetForm();
        })
        .catch(() => {
          alert('Error');
        })
        .finally(() => actions.setSubmitting(false));
    }}
    validate={(values) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const errors = {};
      if (!values.name) {
        errors.name = 'Name Required';
      }
      if (!values.email || !emailRegex.test(values.email)) {
        errors.email = 'Valid Email Required';
      }
      if (!values.message) {
        errors.message = 'Message Required';
      }
      return errors;
    }}
  >
    {({ touched, errors, values, handleChange, handleBlur, handleSubmit }) => (
      <FormStyles name={form} data-netlify>
        <InputWrapper>
          <Input
            border={touched.name && errors.name && '1px solid red'}
            placeholder="Dein Name"
            name="name"
          />
          <Label className="label" htmlFor="name" data-content="Dein Name">
            <span className="hidden--visually">Dein Name</span>
          </Label>
        </InputWrapper>
        <InputWrapper>
          <Input
            border={touched.email && errors.email && '1px solid red'}
            placeholder="Deine E-Mail"
            name="email"
          />
          <Label className="label" htmlFor="email" data-content="Dein E-Mail">
            <span className="hidden--visually">Dein E-Mail</span>
          </Label>
        </InputWrapper>
        <InputWrapper>
          <Textarea
            border={touched.message && errors.message && '1px solid red'}
            placeholder="Deine Nachricht"
            name="message"
            component="textarea"
            rows="4"
          />
          <LabelTextarea
            className="label"
            htmlFor="message"
            data-content="Dein Nachricht"
          >
            <span className="hidden--visually">Dein Nachricht</span>
          </LabelTextarea>
        </InputWrapper>
        <Fieldset>
          <ButtonSubmit type="submit">
            {form === 'shooting'
              ? 'Zum Shooting anmelden'
              : 'Nachricht Absenden'}
          </ButtonSubmit>
        </Fieldset>
      </FormStyles>
    )}
  </Formik>
);

export default ContactForm;
