import React from 'react';
import { useStaticQuery, StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import mq from '../styles/media-queries';
import { ButtonWhite } from '../styles/common/Element';

const InfoGrid = styled.section`
  display: grid;
  grid-gap: 0rem;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  overflow: hidden;
  ${mq.sm} {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
`;

const PersonStyles = styled.div`
  position: relative;
  a {
    text-decoration: none;
  }
`;

export default function SectionGesichter() {
  const data = useStaticQuery(graphql`
    query GesichterQuery {
      gesichter: allSanityPerson(limit: 10) {
        nodes {
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
          id
          name
        }
      }
    }
  `);
  const persons = data.gesichter.nodes;
  return (
    <>
      <InfoGrid id="kulturgesichter">
        {persons.map((person) => (
          <PersonStyles key={person.id}>
            <Img fluid={person.image.asset.fluid} alt={person.name} />
          </PersonStyles>
        ))}
      </InfoGrid>
      <AllLinkWrapper>
        <ButtonWhite
          as={Link}
          to="/kulturgesichter"
          title="Kulturgesichter NRW"
        >
          Alle Kulturgesichter
        </ButtonWhite>
      </AllLinkWrapper>
    </>
  );
}

const AllLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5rem 2rem;
`;
