import React from 'react';
import { useStaticQuery, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import BackgroundSection from './BackgroundSection';
import Image from './Image.js';
import mq from "../styles/media-queries"
import { Col, Col_2, Col_4, Container, Row } from '../styles/common/Layout';
import { H1Box } from '../styles/common/Element';

const SectionStyles = styled.section`
  background-color: var(--black);
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 200px;
  padding: 6em 0px;
  overflow: hidden;
`;

const CitiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`

const City = styled(Col)`
  margin-bottom: 30px;

  ${mq.md} {
    flex: 0 0 50%;
    max-width: 50%;
  }
  ${mq.lg} {
    flex: 0 0 30%;
    max-width: 30%;
  }
`

export default function SectionPartnerCity() {
  const data = useStaticQuery(graphql`
    query PartnerCityQuery {
      cities: allSanityPartnerCity {
      nodes {
        _id
        partnerCity
        partnerCityUrl
      }
    }
  }
`);

  const cities = data.cities.nodes;
  return (
    <>
      <SectionStyles id="partnerCities">
        <Container>
          <Row>
            <Col>

          <H1Box>Partnerstädte</H1Box>
          {cities &&
            <CitiesWrapper>
              {cities.map(city => (
                <City key={city._id}>
                  <p>
                    <strong>{city.partnerCity}</strong><br />
                    <a rel="noreferrer" href={city.partnerCityUrl}>{city.partnerCityUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")}</a>
                  </p>
                </City>
              ))}
            </CitiesWrapper>
          }
          </Col>
          </Row>
        </Container>
      </SectionStyles>
    </>
  );
}
