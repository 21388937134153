import React from 'react';
import Img from 'gatsby-image';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import ContactForm from './ContactForm/ContactForm.js';
import Video from './Video/Video.js';
import { H1BoxBlack } from '../styles/common/Element';
import { Container } from '../styles/common/Layout.js';

const SectionStyles = styled.section`
  background-color: var(--white);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 3rem 0;
`;

const ContentStyles = styled(Container)`
  color: var(--black);
  text-align: center;

  p {
    text-align: center;
  }
`;

const GridStyles = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  p {
    text-align: left;
    color: var(--black);
  }
`;

export default function SectionContact() {
  return (
    <>
      <SectionStyles id="contact">
        <ContentStyles>
          <H1BoxBlack>KONTAKT &amp; ANMELDUNG</H1BoxBlack>
          <GridStyles>
            <div className="item">
              <Video
                videoSrcId="8SG9dqFCcRw"
                videoWidth="100%"
                videoHeight="auto"
                videoSrcImage="mqdefault"
              />
            </div>
            <div className="item">
              <p>
                Wenn du generelle Fragen rund um die Aktion Kulturgesichter NRW
                hast oder du mit uns aus anderen Gründen in Kontakt treten
                willst, nutze bitte dieses Kontaktformular. <br />
                <br />
                Wir melden uns schnellstmöglich bei Euch.
              </p>
              <ContactForm form="contact" />
            </div>
          </GridStyles>
        </ContentStyles>
      </SectionStyles>
    </>
  );
}
